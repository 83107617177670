<template>
  <router-view />
</template>

<style>
html{margin: 0px; padding: 0px;font-size: 12px;}
.font_size_12{font-size: 12px;}
.cursor{cursor: pointer}
/* 页面内容区域背景色全局定义 */
.ant-layout{background-color: #001529;}
/* 全局内容区域圆角样式定义*/
.content_border{margin: 6px 0 0 0;border-top-left-radius:5px; padding:14px;background:#fff;}
/* { margin: '6px 0 0 0', borderTopLeftRadius:'5px', padding: '14px',background: '#fff',} */
</style>